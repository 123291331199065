import { useCallback, useEffect, useState } from "react";
import Modal from "../Modal";
import { WhatsNewContentContainer, WhatsNewCoverMediaImage, WhatsNewPopupContainer, WhatsNewPopupTitleContainer } from "./styles";

import { t } from "i18next";
import { toNumber } from "lodash";
import Markdown from "markdown-to-jsx";
import i18n from "../../i18n";
import gaEvents from "../../services/analytics_events";
import rcValues, { WhatsNewPopupData } from '../../services/remote_config_values';

const displayedNewsByIdKey = '@xtimes:whats-new.news-displayed-by-id';
const displayedNewsLastDisplayedAt = '@xtimes:whats-new.last-displayed-time';
const minimumDisplayDelayInMs = 5 * 60 * 1000;

const WhatsNewCoverMediaYouTubeVideo: React.FC<React.PropsWithChildren<{ url: string }>> = ({ url }) => {
  const embedUrl = useCallback(() => {
    const videoId = url.split('v=')[1];
    return `https://www.youtube-nocookie.com/embed/${videoId}`;
  }, [url]);

  return (<>
    <iframe className='coverMediaVideo' title='cover_media' src={embedUrl()}
      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
      referrerPolicy='strict-origin-when-cross-origin'
      allowFullScreen></iframe>
  </>);
}

const WhatsNewCoverMedia: React.FC<React.PropsWithChildren<{ mediaURL: string }>> = ({ mediaURL }) => {
  return (
    <>
      {mediaURL.search('youtube') > 0
        ? <WhatsNewCoverMediaYouTubeVideo url={mediaURL} />
        : <WhatsNewCoverMediaImage src={mediaURL} />
      }
    </>
  );
}

const WhatsNewContent: React.FC<React.PropsWithChildren<{ data: WhatsNewPopupData }>> = ({ data }) => {
  return (
    <>
      <WhatsNewContentContainer>
        <WhatsNewCoverMedia mediaURL={data.coverMedia} />
        <Markdown className='markdownSection'>
          {data.description}
        </Markdown>
        {data.sections.map((v, i) => <Markdown className='markdownSection' key={i}>{v.content}</Markdown>)}
      </WhatsNewContentContainer>
    </>
  );
}

const WhatsNewPopupTitle: React.FC<React.PropsWithChildren<{ data: WhatsNewPopupData }>> = ({ data }) => {

  const placeDateComponent = () => {
    const dateOptions: Intl.DateTimeFormatOptions = {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    };

    return (data !== null)
      ? <p>{new Date(data.date).toLocaleDateString(i18n.language, dateOptions)}</p>
      : <></>;
  };

  return (
    <>
      <WhatsNewPopupTitleContainer>
        <h1>{t(`components.whats-new-popup.title`)}</h1>
        {placeDateComponent()}
      </WhatsNewPopupTitleContainer>
    </>
  );
}

const WhatsNewPopup: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [showModal, setShowModal] = useState(true);
  const [whatsNewPopupData, setWhatsNewPopupData] = useState<WhatsNewPopupData[]>(null);

  const handleWhatsNewPopupData = (data: WhatsNewPopupData[]) => {
    const filteredData = data.filter((v) => {
      const isValidDate = (() => {
        const date = new Date(v.date);
        const endDate = new Date(v.date);
        endDate.setDate(endDate.getDate() + 15);

        const now = new Date();
        return now >= date && now <= endDate;
      })();

      const wasAlreadyDisplayed = (() => {
        return (
          new Set(JSON.parse(
            localStorage.getItem(displayedNewsByIdKey) ?? '[]'
          ) as string[]).has(v.id)
        );
      })();

      const isAvailableOnUserLanguage = (() => {
        return (new Set(v.languages))
          .has(i18n.language);
      })();

      return isValidDate && !wasAlreadyDisplayed && isAvailableOnUserLanguage;
    });

    if (filteredData.length > 0) {
      setWhatsNewPopupData(filteredData);
      setShowModal(true);
      gaEvents.whatsNewPopupEvent.emit({
        type: 'DISPLAY',
        id: filteredData[0].id
      })
      localStorage.setItem(displayedNewsLastDisplayedAt, `${Date.now()}`);
    }
  };

  useEffect(() => {
    const lastDisplayedTime: number = toNumber(localStorage.getItem(displayedNewsLastDisplayedAt) ?? '0');
    if ((lastDisplayedTime + minimumDisplayDelayInMs) >= Date.now()) {
      return;
    }

    rcValues.whatsNewPopup.getAsync()
      .then((v) => handleWhatsNewPopupData(v));
  }, []);

  const handleOnClose = () => {
    if (whatsNewPopupData) {
      const displayedNewsById = JSON.parse(
        localStorage.getItem(displayedNewsByIdKey) ?? '[]'
      ) as string[];

      displayedNewsById.push(whatsNewPopupData[0].id);
      localStorage.setItem(displayedNewsByIdKey, JSON.stringify(displayedNewsById));

      gaEvents.whatsNewPopupEvent.emit({
        type: 'DISMISS',
        id: whatsNewPopupData[0].id
      })
    }
    setShowModal(false);
  };

  return (
    <>
      <Modal
        open={showModal && whatsNewPopupData !== null}
        onClose={handleOnClose}
        titleLeft={(<WhatsNewPopupTitle data={whatsNewPopupData ? whatsNewPopupData[0] : null} />)}
      >
        <WhatsNewPopupContainer>
          {whatsNewPopupData
            && <WhatsNewContent data={whatsNewPopupData[0]} />
          }
        </WhatsNewPopupContainer>
      </Modal>
    </>
  );
}

export default WhatsNewPopup;
