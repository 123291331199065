import styled, { css } from 'styled-components';

interface ContainerProps {
  $isLastCard?: boolean;
}

export const Container = styled.div<ContainerProps>`
  height: 235px;
  width: 235px;
  border-radius: 22px;
  border: 1px solid #000;
  background: #fff;
  padding: 20px;
  box-shadow: 4px 4px #000;
  transition: box-shadow 0.08s ease-in-out, transform 0.08s ease-in-out;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  transition: filter 0.2s;

  @media (max-width: 515px) {
    height: 185px;
    width: 185px;
  }

  @media (max-width: 443px) {
    height: 155px;
    width: 155px;
  }

  @media (max-width: 351px) {
    height: 130px;
    width: 130px;
  }

  &:hover {
    filter: ${props => (!props.$isLastCard ? '' : 'none')};
  }

  ${props =>
    !props.$isLastCard &&
    css`
      cursor: pointer;
    `}

  .loading {
    color: #000;
  }

  &:hover,
  &:focus {
    opacity: 0.9;
    outline: 0;
  }

  &:active {
    box-shadow: none;
    transform: translate3d(4px, 4px, 0px);
  }
`;

export const LargeContainer = styled.div`
  border-radius: 22px;
  border: 1px solid #000;
  box-shadow: 4px 4px #000;

  margin: 40px 20px;

  display: flex;
  flex-direction: column;

  align-items: center;

  -webkit-tap-highlight-color: transparent;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
    display: grid;
  }

  @media (max-width: 768px) {
    div.alignImg {
      height: 90px;
    }
  }

  div.alignCashback {
    width: 100%;
  }

  > div {
    display: flex;
    align-items: center;
    flex: 1;

    height: 170px;
    padding: 20px 0;

    @media (max-width: 768px) {
      padding: 0;
    }
  }
  > div.empty {
    display: none;

    @media (max-width: 768px) {
      padding: 0;
    }
  }
  .alignImg {
    width: 100%;

    img {
      width: 100%;
      padding: 0 20px;
    }
  }

  .cashbackContent {
    margin: 0 auto;

    height: 100%;
    width: 100%;
    max-width: 280px;

    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 768px) {
      height: 126px;
    }

    @media (max-width: 350px) {
      margin: 0 15px;
    }

    > span {
      margin-bottom: 16px;
      font-size: 20px;
    }

    .alignButtons {
      width: 100%;

      .button.modal {
        margin: 6px 0;
      }

      > button:first-child {
        margin-bottom: 8px;
      }
    }
  }
`;

export const Title = styled.span`
  font-size: 12px;
  font-family: ${props => props.theme.font.default};
  color: #222222;
`;

export const Logo = styled.img`
  width: 200px;
  max-height: 120px;
  object-fit: contain;

  @media (max-width: 515px) {
    width: 140px;
  }

  @media (max-width: 443px) {
    width: 132px;
  }

  @media (max-width: 351px) {
    width: 96px;
  }
`;

export const Divider = styled.div`
  height: 100%;
  width: 1px;
  margin: 0 12px;
  background: #ababab;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const Empty = styled.div`
  flex: 1;
`;

export const CashbackInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CurrentCashback = styled.span`
  text-align: center;
  font-size: 24px;
  font-family: ${props => props.theme.font.default};
  font-weight: bold;
  color: #222222;

  @media (max-width: 443px) {
    font-size: 17px;
  }

  @media (max-width: 351px) {
    font-size: 15px;
  }
`;

export const Description = styled.span`
  font-size: 12px;
  font-family: ${props => props.theme.font.default};
  color: #222222;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const LastCardTitle = styled.span`
  font-size: 26px;
  font-family: ${props => props.theme.font.default};
  font-weight: bold;
  color: #525252;

  @media (max-width: 768px) {
    text-align: center;
    font-size: 22px;
  }
`;

export const SubTitle = styled.span`
  font-size: 14px;
  font-family: ${props => props.theme.font.default};
  color: #525252;
  text-align: center;

  padding: 10px 20px;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const CategoryTitle = styled.div`
  font-size: 18px;
  font-family: ${props => props.theme.font.default};
  color: #525252;

  padding: 20px 0;
`;

export const AlignCategoryIcon = styled.div`
  padding: 12px;
`;

export const CategoryItemContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const CategoryItemCircle = styled.div`
  height: 16px;
  width: 16px;

  background: ${props => props.theme.colors.secondary};
  border-radius: 50%;
  margin: 0 12px;
`;

export const CategoryItemTitle = styled.span`
  font-size: 18px;
  font-family: ${props => props.theme.font.default};
`;
