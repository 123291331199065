import { Container } from './style';
import Win from '../../../../assets/MobileMenu/win.png';

const RewardCard: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <Container>
      <img src={Win.src} alt="BitReward" />
      <div
        onClick={() => {
          window.location.href = '/news';
        }}
      >
        <h1>Ganhe Bitcoin ao ler notícias!</h1>
        <span>
          Ganhe diariamente satoshis e aprenda mais sobre o mercado
        </span>
      </div>
    </Container>
  );
};

export default RewardCard;
