import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

function withAuth<P>(WrappedComponent: React.ComponentType<React.PropsWithChildren<P>>) {
  const WithAuth = (props: P) => {
    const router = useRouter();

    const [hasToken, setHasToken] = useState(false);

    useEffect(() => {
      if (typeof window === 'undefined' || !router.isReady) {
        return;
      }

      const path = router.pathname.replace(/\[([^\]]+)\]/g, (_, key) => router.query[key] as string);

      const accessToken = localStorage.getItem('@coingoback:token');

      if (!accessToken) {
        window.location.href = `/login?from=${encodeURIComponent(path)}`;
      } else {
        setHasToken(true);
      }

    }, [router.isReady, router.pathname, router.query]);

    return !hasToken ? <></> : <WrappedComponent {...props} />;
  };

  WithAuth.displayName = `WithAuth(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;

  return WithAuth;
}

export default withAuth;
