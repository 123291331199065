
import { HomeProvider } from './home';
import { CashbackProvider } from './cashback';
import { AuthProvider } from './auth';
import { UserProvider } from './user';
import { AdvertiserProvider } from './advertiser';
import { CashoutProvider } from './cashout';
import { NewsProvider } from './news';
import { ForgotPasswordProvider } from './forgotPassword';
import { QuestsProvider } from './quests';
import { AirdropProvider } from './airdrop';

const AppProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <AuthProvider>
    <UserProvider>
      <CashbackProvider>
        <AdvertiserProvider>
          <CashoutProvider>
            <HomeProvider>
              <NewsProvider>
                <ForgotPasswordProvider>
                  <QuestsProvider>
                    <AirdropProvider>
                      {children}
                    </AirdropProvider>
                  </QuestsProvider>
                </ForgotPasswordProvider>
              </NewsProvider>
            </HomeProvider>
          </CashoutProvider>
        </AdvertiserProvider>
      </CashbackProvider>
    </UserProvider>
  </AuthProvider>
);

export default AppProvider;
