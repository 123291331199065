import styled from 'styled-components';

interface ButtonProps {
  $active?: boolean;
}

export const Button = styled.button<ButtonProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 50px;
  justify-content: space-between;
  color: #fff;
  width: 100%;
  ${props => {
    if (props.$active) {
      return `
          filter: invert(51%) sepia(96%) saturate(4862%) hue-rotate(3deg) brightness(105%) contrast(106%);
        `;
    }
  }};
  img {
    margin-top: -10px;
    margin-bottom: 5px;
  }
`;
