import { useState } from 'react';

import Modal from '../../../Modal';
import { Container } from './styles';
import { Button } from '../../../UI';
import { useUser } from '../../../../hooks/user';
import { toastError, toastSuccess } from '../../../Toast';

interface Props {
  open: boolean;
  onClose: (done?: boolean) => void;
}

const Guest: React.FC<React.PropsWithChildren<Props>> = ({ open, onClose }) => {
  const [code, setCode] = useState('');
  const { setReferrer } = useUser();

  const only10lenfg = (e: React.ChangeEvent<HTMLInputElement>) => {
    const regex = /^[a-z]+$/;
    const value = e.target.value.toLowerCase();

    if (value === '' || regex.test(value)) {
      setCode(value);
    }
  };

  const handleSetReferrer = async () => {
    try {
      const message = await setReferrer(code);
      toastSuccess(message);
      onClose(true);
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Container>
          <h1>Fui indicado</h1>
          <p>Insira o código de convite de quem te indicou:</p>

          <input
            min="0"
            type="text"
            value={code}
            maxLength={10}
            autoComplete="off"
            onChange={only10lenfg}
          />

          <Button className="Button" onClick={handleSetReferrer}>
            Confirmar
          </Button>
        </Container>
      </Modal>
    </>
  );
};
export default Guest;
