import styled from 'styled-components';

type BoxProps = {
  $flex?: number;
  $dark?: boolean;
};

export const Wraper = styled.div`
  display: flex;
  margin: 20px 0;

  @media (min-width: 1161px) {
    align-items: center;
    justify-content: space-between;
  }

  @media (max-width: 1160px) {
    flex-direction: column;
  }

  @media (min-width: 780px) and (max-width: 1160px) {
    .alignCards > :first-child {
      margin-right: 40px;
    }
  }

  .alignCards {
    flex: 4;
    display: flex;
  }

  .cashbackCard {
    min-height: 186px;

    @media (max-width: 370px) {
      padding: 26px;
    }
  }

  @media (max-width: 780px) {
    .alignCards {
      flex-direction: column;
    }
  }

  @media (max-width: 350px) {
    .cashbackInfo {
      flex-direction: column;
      align-items: flex-start;
      margin-top: 12px;

      span {
        margin-left: 0;
      }
    }
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const Box = styled.div<BoxProps>`
  padding: 35px;
  border-radius: 22px;
  flex: ${(props: BoxProps) => props.$flex || 1};
  margin: 20px;
  justify-content: space-around;
  display: flex;
  flex-direction: column;
  height: 185px;
  width: 185px;
  border-radius: 22px;

  ${props => {
    if (props.$dark) {
      return `
        background: ${
          props.$dark ? '#000' : '#ffffff'
        } 0% 0% no-repeat padding-box;
        box-shadow: #fff 4px 4px 0px -1px, #000 4px 4px;
        border: 1px solid #ffffff;
      `;
    }

    return `
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 4px 4px #000;
      border: 1px solid #000;
    `;
  }}

  @media (max-width: 1160px) {
    width: 100%;
    margin: 20px 0;
  }

  @media (max-width: 780px) {
    margin: 12px 0;
  }
`;

export const Title = styled.h1`
  font: normal normal bold 2.4em Rubik;
  color: #222222;

  @media (max-width: 443px) {
    font-size: 2em;
  }

  @media (max-width: 370px) {
    font-size: 1.8em;
  }
`;

export const Text = styled.span`
  font: normal normal normal 12px/11px Rubik;
  color: #222222;
  & a {
    font: normal normal normal 12px Rubik;
    color: ${props => props.theme.colors.primary.main};
    text-decoration: none;
    margin-top: 20px;
    align-self: flex-start;
  }
  & a:hover {
    color: ${props => props.theme.colors.primary.hover};
  }
`;

export const FeaturedText = styled.span`
  font: normal normal bold 12px Rubik;
  margin-left: 20px;
`;
