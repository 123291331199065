import { useEffect, useState } from 'react';
import Head from 'next/head';
import { Container, PageContent, Content, ScrollContent } from './styles';

import MobileMenu from '../../components/MobileMenu';
import Header from '../../components/Header';
import IndicationBar from '../../components/Indication/Bar';
import OldHeader from '../../components/OldHeader';
import { isAppV2, isOldApp } from '../../services/versionManager';
import WhatsNewPopup from '../../components/WhatsNewPopup';

const DefaultLayout: React.FC<React.PropsWithChildren<unknown>> & { hideIndicationBar?: boolean } = props => {
  const [width, setWidth] = useState(0);
  const [showSurvey, setShowSurvey] = useState(false);

  useEffect(() => {
    setWidth(window.innerWidth);

    function handleResize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [newHeaderMenu, setNewHeaderMenu] = useState(null);

  useEffect(() => {
    setNewHeaderMenu(isAppV2() || !isOldApp());
  }, []);

  useEffect(() => {
    setShowSurvey(!isAppV2() && width >= 770);
  }, [width]);

  return (
    <>
      <Head>
        <title>Cointimes</title>
      </Head>
      <Container>
        <PageContent>
          {newHeaderMenu !== null ? (newHeaderMenu == true ? <Header/> : <OldHeader/>) : <></>}
          <ScrollContent>
            {width >= 770 && !window.location.pathname.startsWith('/airdrop/kanna') && <IndicationBar />}
            {showSurvey && <div style={{display: 'flex', width: '100%', justifyContent: 'center', alignContent: 'center'}}><div id='hotjar_element_holder' style={{ padding: '10px 300px' }} /></div>}
            <Content>{props.children}</Content>
          </ScrollContent>
        </PageContent>
        <MobileMenu/>
        <WhatsNewPopup/>
      </Container>
    </>
  );
};

export default DefaultLayout;
