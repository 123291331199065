import * as Card from './styles';
import Link from 'next/link';

type ActionType = {
  href?: string;
};

const Action: React.FC<React.PropsWithChildren<ActionType>> = props => {
  return (
    <Card.Text>
      <Link href={props.href}>{props.children}</Link>
    </Card.Text>
  );
};

const components = { Action, ...Card };

export default components;
