import styled from 'styled-components';
import AsyncSelect from 'react-select/async';

interface CustomSelectProps {
  $dark?: boolean;
}

// const selectStyles = {
//   menuList: () => ({
//     zIndex: 999,
//   }),
// };

export const Container = styled.div`
  flex: 1;
  padding-right: 6px;
`;

export const CustomSelect = styled(AsyncSelect)<CustomSelectProps>
`
  & .react-select__control {
    z-index: 999;

    width: 100%;
    border: none;
    box-shadow: none;
    background: transparent;
    cursor: text;
  }

  & .react-select__single-value  {
    color: ${props => props.$dark ? 'white' : 'black'} !important;
  }

  & .react-select__placeholder {
    color: ${props => props.$dark ? 'white' : 'black'}
  }

  & .react-select__input {
    color: ${props => props.$dark ? 'white' : 'black'} !important;
  }

  & .react-select__menu {
    z-index: 999;
    margin-left: -34px;
    width: calc(100% + 34px);
  }

  .css-b8ldur-Input {
    color: ${props => props.$dark ? 'white' : 'black'}
  }

  .css-1uccc91-singleValue{
    color: ${props => props.$dark ? 'white' : 'black'}
  }
`;
