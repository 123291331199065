import { gql } from '@apollo/client';
import { createContext, useContext } from 'react';
import { AdvertiserShort } from '../models/advertiser';
import { initializeApollo } from '../services/apolloClient';

const apolloClient = initializeApollo();

interface AdvertiserContextData {
  getAdvertiserBySlug(slug: string): Promise<AdvertiserShort>;
}

const AdvertiserContext = createContext<AdvertiserContextData>(
  {} as AdvertiserContextData
);

export const AdvertiserProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const getAdvertiserBySlug = async (
    slug: string
  ): Promise<AdvertiserShort> => {
    return new Promise<AdvertiserShort>((resolve, reject) => {
      apolloClient
        .query({
          query: gql`
            query advertiserBySlug($slug: String!) {
              advertiserBySlug(slug: $slug) {
                id
                image_url
                name
                commission_description
                domain
                commission_info {
                  commission_type
                  commission_currency
                  commission_amount_formatted
                  description
                }
              }
            }
          `,
          variables: {
            slug,
          },
        })
        .then(async response => {
          resolve(response?.data?.advertiserBySlug);
        })
        .catch(error => {
          reject(error.response?.data ? error.response.data : error.message);
        });
    });
  };

  return (
    <AdvertiserContext.Provider value={{ getAdvertiserBySlug }}>
      {children}
    </AdvertiserContext.Provider>
  );
};

function useAdvertiser(): AdvertiserContextData {
  const context = useContext(AdvertiserContext);

  if (!context) {
    throw new Error(
      ' useAdvertiser must be used within an AdvertiserProvider '
    );
  }
  return context;
}

export { AdvertiserContext, useAdvertiser };
