import { Id, toast } from 'react-toastify';

interface ErrorMessage {
  response ?: {
      data?: {
        message?: string
      }
      message?: string
    }
  message?: string
}

export function toastError(error?: string | ErrorMessage ): Id {
  let finalMessage = '';
  const errorMessage = error as ErrorMessage;

  // Revisar logica de erros
  if (errorMessage) {
    if (errorMessage?.response?.data) {
      if (errorMessage?.response?.data?.message) {
        finalMessage = errorMessage.response.data.message;
      }
    } else if (errorMessage?.response?.message) {
      finalMessage = errorMessage?.response?.message;
    }
  } else if (errorMessage?.message) {
    finalMessage = errorMessage?.message;
  } else {
    finalMessage = error as string;
  }

  return toast.error(
    finalMessage !== '' && finalMessage !== undefined
      ? finalMessage
      : 'Erro ao Realizar a Operação',
    {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    }
  );
}

export function toastSuccess(msg?: string): Id {
  return toast.success(msg ? msg : 'Operação realizada com sucesso!', {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
}

export function toastInfo(msg: string): Id {
  return toast.info(msg, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
}
