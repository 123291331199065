import { gql } from '@apollo/client';
import { createContext, useContext } from 'react';
import {
  AdvertiserListItem,
  // CarrouselImage,
  Advertiser,
  TrackerInput,
} from '../models/home';
import { initializeApollo } from '../services/apolloClient';

import { formatGraphQlInput } from '../utils/formatters';

const apolloClient = initializeApollo();

interface HomeContextData {
  // getCarouselImages(): Promise<CarrouselImage[]>;
  getAdvertisers(
    limit: number,
    page: number,
    tag?: string
  ): Promise<AdvertiserListItem[]>;
  getAdvertisersByString(inputValue: string): Promise<Advertiser[]>;
  createTracker(input: TrackerInput): Promise<string>;
}

const HomeContext = createContext<HomeContextData>({} as HomeContextData);

export const HomeProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  // const getCarouselImages = async () => {
  //   return [
  //     {
  //       image_large: 'http://via.placeholder.com/1160x240',
  //     },
  //     {
  //       image_large: 'http://via.placeholder.com/1160x240',
  //     },
  //   ];
  // };
  const getAdvertisers = async (limit: number, page: number, tag?: string) => {
    return new Promise<AdvertiserListItem[]>((resolve, reject) => {
      apolloClient
        .query({
          query: gql`
            query advertisers(
              $limit: Int!
              $offset: Int!
              $country: String!
              $tag: String
            ) {
              advertisers(
                limit: $limit
                offset: $offset
                country: $country
                tag: $tag
              ) {
                name
                slug
                image_url
                link
                commission_description
              }
            }
          `,
          variables: {
            limit,
            offset: page * limit,
            country: 'BR',
            tag,
          },
        })
        .then(async response => {
          resolve(response?.data?.advertisers);
        })
        .catch(error => {
          reject(error.response?.data ? error.response.data : error.message);
        });
    });
  };

  const getAdvertisersByString = async (inputValue: string) => {
    return new Promise<Advertiser[]>((resolve, reject) => {
      apolloClient
        .query({
          query: gql`
            query {
              advertisers(country: "BR", search: ${JSON.stringify(
                inputValue
              )}, limit: 10) {
                name
                slug
              }
            }
          `,
        })
        .then(async response => {
          resolve(response?.data?.advertisers);
        })
        .catch(error => {
          reject(error.response?.data ? error.response.data : error.message);
        });
    });
  };

  const createTracker = async (inputValue: TrackerInput) => {
    return new Promise<string>((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`
            mutation {
              createTracker(input: ${formatGraphQlInput(inputValue)}) {
                link_redirect
              }
            }
          `,
        })
        .then(async response => {
          resolve(response?.data?.createTracker?.link_redirect);
        })
        .catch(error => {
          reject(error.response?.data ? error.response.data : error.message);
        });
    });
  };

  return (
    <HomeContext.Provider
      value={{
        // getCarouselImages,
        getAdvertisers,
        getAdvertisersByString,
        createTracker,
      }}
    >
      {children}
    </HomeContext.Provider>
  );
};

function useHome(): HomeContextData {
  const context = useContext(HomeContext);

  if (!context) {
    throw new Error(' useHome must be used within an HomeProvider ');
  }
  return context;
}

export { HomeContext, useHome };
