import { useState } from 'react';
import { Container } from './styles';
import router from 'next/router';
import IndicationExt from '../../Modal/indicationExt';
import { FlatButton } from '../../UI/Button/styles';
import { useTranslation } from 'react-i18next';

const Bar: React.FC<React.PropsWithChildren<unknown>> = () => {
  const {t} = useTranslation();
  const [openModalInvite, setOpenModalInvite] = useState<boolean>(false);

  return (
    <>
      {openModalInvite && (
        <IndicationExt open={openModalInvite} onClose={() => {setOpenModalInvite(false)}} />
      )}
      <Container>
        <div className="content">
          <div
            className="text"
            onClick={() => {
              router.push('/recommendation');
            }}
          >
            <p className="title">
              {t('components.indication-bar.refer-cointimes-and-earn')}
            </p>
            <p className="subtitle">
              {t('components.indication-bar.earn-points-daily-for-the-xtimes-airdrop')}
            </p>
          </div>
          <div className="actions">
            <FlatButton $dark onClick={() => {
              setOpenModalInvite(true);
            }}>
              {t('components.indication-bar.refer-a-friend')}
            </FlatButton>
            <FlatButton $dark onClick={() => {
              router.push('/recommendation');
            }}>
              {t('components.indication-bar.see-referrals')}
            </FlatButton>
            {/* {!user?.referrer_code && verifyDate(new Date(user?.verified_at)) && (
              <button className="secondary" onClick={handleOpenModalGuest}>
                Fui indicado
              </button>
            )} */}
          </div>
        </div>
      </Container>
    </>
  );
};

export default Bar;
