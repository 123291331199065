import styled from 'styled-components';

interface SubTitleProps {
  $size?: number;
  $bold?: boolean;
}

export const SubTitle = styled.h1<SubTitleProps>`
  font-size: ${props => props.$size ?? 24}px;
  font-family: ${props => props.theme.font.default};
  font-weight: ${props => props.$bold ? 'bold' : 'normal'};
  color: #525252;
`;
