import { useState } from 'react';
import { Button } from '../../UI';
import { Container } from './styles';
import { CircularProgress } from '@mui/material';
import Modal from '../../Modal';
import { useAuth } from '../../../hooks/auth';
import { t } from 'i18next';

interface Props {
  open: boolean;
  onClose: (done?: boolean) => void;
}

const DeleteAccountModal: React.FC<React.PropsWithChildren<Props>> = ({ open, onClose }) => {
  const [deleted, setDeleted] = useState(false);
  const { deleteUser } = useAuth();

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Container>
          <h1>{t('components.profile-modal.delete-account.title')}</h1>
          <p>
            <span style={{fontWeight: 'bold'}}>{t('components.profile-modal.delete-account.are-you-sure')}</span>
            <br />
            <span style={{color: 'red'}}>{t('components.profile-modal.delete-account.this-cannot-be-undone')}</span>
          </p>
          <div>
            <Button
              onClick={() => {
                onClose();
              }}
            >
              {t('components.profile-modal.delete-account.cancel-button')}
            </Button>
            <Button
              onClick={async () => {
                await deleteUser();
                window.location.replace('/');
                setDeleted(true);
              }}
            >
              {deleted ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                  t('components.profile-modal.delete-account.delete-account-button')
              )}
            </Button>
          </div>
        </Container>
      </Modal>
    </>
  );
};

export default DeleteAccountModal;
