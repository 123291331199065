import Skeleton from '../../Skeleton';

import { Container } from './styles';

const LoadingSearchMobile: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <Container>
      <Skeleton className="container" />
    </Container>
  );
};

export default LoadingSearchMobile;
