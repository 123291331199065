import styled from 'styled-components';

export const Header = styled.div`
  background-color: #000;
  display: flex;
  flex-direction: column;
  border-radius: 0 0 24px 24px;
  padding-bottom: 20px;

  @media (min-width: 770px) {
    display: none;
  }

  div.arrow {
    display: flex;
    justify-content: center;
    padding: 10px;
    background: #000;
    border-radius: 0 0 22px 22px;
    button {
      width: 100%;
    }
  }

  div.arrow.hide {
    margin-bottom: 10px;
    button {
      transform: rotate(0deg);
    }
  }

  div.arrow.show {
    margin-bottom: 10px;
    button {
      transform: rotate(180deg);
      width: 100%;
    }
  }

  div.mobileSearch {
    display: flex;
    justify-content: center;
    padding: 0 20px;

    margin: 30px 0;
  }

  div.rewardCard {
    padding: 0 20px;
  }

  .indication {
    background-color: #fff;
    display: flex;
    align-items: center;
    padding: 7px 14px;
    border-radius: 8px;
    margin: 0 30px;

    .indication-content {
      margin-left: 10px;

      p {
        font-weight: 600;
        font-size: 12px;
      }

      span {
        font-size: 8px;
        color: #757575;
      }
    }
  }
`;

export const Container = styled.div`
  @media (min-width: 770px) {
    display: none;
  }

  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 82px;
  padding: 0 30px;
  background: #000;

  .icon {
    height: 40px;
  }

  .entrarapp {
    display: flex;
    gap: 15px;

    button {
      color: #fff;
    }
  }

  .balance {
    display: flex;
    background-color: #fff;
    height: 25px;
    align-items: center;
    min-width: 86px;
    border-radius: 40px;

    .balance-icon {
      height: 23px;
      width: 23px;
      background: #000;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 7px;
      margin-left: 1px;
    }
  }
`;

export const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 280px;
  margin: 0 20px;
  color: #fff;
  cursor: pointer;
  @media (max-width: 769px) {
    width: 150px;
    margin: 0;
  }
`;

export const ProfileContainerApp = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  margin: 0;
  color: #fff;
  cursor: pointer;
`;

export const ProfileImage = styled.img`
  color: #fff;
`;

export const DefaultProfileImage = styled.div`
  padding: 4px 6px;
  margin: 0 10px;

  color: white;
  border-radius: 50px;
  border: 2px solid;
`;

export const DefaultProfileImageApp = styled.div`
  padding: 4px 6px;
  margin: 0 10px;

  color: black;
  border-radius: 50px;
  border: 2px solid;
`;

export const UserName = styled.span`
  padding: 2px;

  font-size: 14px;
  font-family: ${props => props.theme.font.default};
  /* font-weight: bold; */
`;

export const Linha = styled.div`
  display: flex;
  justify-content: center;

  background: #000;

  div {
    width: 80vw;
    height: 1px;
    background: #fff;
  }
`;

export const BoxSaldo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 26px 0;
  color: #F44805;
  text-align: center;

  p {
    font-size: 20px;
    font-weight: 700;
    margin-right: 10px;
    display: flex;
    align-items: center;
  }
`;

export const MenuCard = styled.div`
  background: #fff;
  border-radius: 22px;
  display: flex;
  flex-shrink: 1;
  flex-direction: column;
  justify-content: space-evenly;

  width: 180px;
  height: 180px;
  padding: 0;
  margin: 0;
  box-shadow: #000 4px 4px 0px -1px, #fff 4px 4px;

  align-items: center;
  text-align: center;
  font-weight: bold;

  &:active {
    box-shadow: none;
    transform: translate3d(4px, 4px, 0px);
  }

  span {
    color: #000;
  }

  @media (max-width: 650px) {
    height: 150px;
    width: 150px;
  }

  @media (max-width: 550px) {
    height: 125px;
    width: 125px;
    font-size: 12px;

    svg {
      height: 60px;
    }
  }

  @media (max-width: 480px) {
    height: 100px;
    width: 100px;
    border-radius: 16px;

    svg {
      height: 50px;
    }
  }

  @media (max-width: 400px) {
    height: 90px;
    width: 90px;
    font-size: 11px;

    svg {
      height: 40px;
    }
  }
`;

export const FeaturedMenu = styled.div`
  padding: 0 30px;
  background: #000;

  @media (max-width: 360px) {
    padding: 0 15px;
  }

  @media (max-width: 320px) {
    padding: 0;
  }

  div.cards {
    display: flex;
    justify-content: space-between;

    a {
      color: #000;
    }

    button {
      padding-right: 5px;
      margin: 10px 0;
    }
  }
`;

export const Animation = styled.div`
  div.animation {
    display: flex;
    justify-content: space-between;
    padding: 0 30px;
    background: #000;

    @media (max-width: 360px) {
      padding: 0 15px;
    }

    @media (max-width: 320px) {
      padding: 0;
    }

    a {
      color: #000;
    }

    button {
      padding-right: 5px;
      margin: 10px 0;
    }
  }
`;

export const ContainerApp = styled.div`
  @media (min-width: 770px) {
    display: none;
  }
  .header {
    padding: 12px;
    border-bottom: 2px solid;
    display: flex;
    align-items: center;
    gap: 20px;

    .icon {
      height: 58px;
      cursor: pointer;
    }
  }

  div.entrarapp {
    display: flex;
    align-self: center;

    div.divider {
      height: 40px;
      width: 1px;
      background-color: #a2a2a2;
      margin: 0 5px;
    }
  }

  div.mobileSearch {
    width: 100%;
  }
`;

export const DefaultHeaderContainer = styled.div<{ $color?: string, $border: boolean}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;

  background: ${props => props.$color ?? '#000'};
  border-radius: 0 0 20px 20px;

  ${({$border}) => $border && `
    border-bottom: 1px solid #878787;
  `}

  @media (min-width: 770px) {
    display: none;
  }

  img {
    transform: rotate(90deg);
    margin-left: 34px;
  }

  h1 {
    color: #fff;
    margin-left: -22px;
  }

  div {
    width: 14px;
  }
`;
