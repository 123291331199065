import styled from 'styled-components';
import Select from 'react-select';

interface ContainerProps {
  $enableShortLabel?: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  padding-left: ${props => (props.$enableShortLabel ? 18 : 8)}px;

  height: 38px;
  border: 1px solid #000;
  box-shadow: 4px 4px #000;
  border-radius: 8px;
`;

export const CustomSelect = styled(Select)
`
  & .react-select__control {
    z-index: 999;

    width: 100%;
    border: none;
    box-shadow: none;
  }
`;
