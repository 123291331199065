import { useCallback, useEffect, useState } from 'react';
import * as Sentry from "@sentry/react";

import {
  BoxSaldo,
  Container,
  Header,
  ProfileContainer,
  ProfileImage,
  UserName,
  DefaultHeaderContainer,
  ContainerApp,
  ProfileContainerApp,
  DefaultProfileImageApp,
} from './styles';
import { Search, User } from 'react-feather';
import Icon from '../../../assets/logo-short-cointimes.svg';
import ProfileIcon from '../../../assets/MobileMenu/profile.svg';
import CoinIcon from '../../../assets/MobileMenu/coin.svg';
import CoinOrangeIcon from '../../../assets/MobileMenu/coin-orange.svg';
import AwardIcon from '../../../assets/MobileMenu/award.svg';
import { useRouter } from 'next/router';
import { useAuth } from '../../../hooks/auth';
import LoadingUser from '../../Shimmer/LoadingUser';
import ProfileModal from '../../ProfileModal';
import Popover from '@mui/material/Popover';
import { UserBalance } from '../../../models/user';
import { useUser } from '../../../hooks/user';
import Varrow from '../../../assets/V-arrow.png';
import { CircularProgress } from '@mui/material';
import { SearchButton, SearchContainer } from '../styles';
import SearchAutocomplete from '../../SearchAutocomplete';
import { useHome } from '../../../hooks/home';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { Option } from '../../../models/searchAutocomplete';
import Link from 'next/link';
import IndicationExt from '../../Modal/indicationExt';
import { useTranslation } from 'react-i18next';
import { isOnPopQuest } from '../../../utils/app';
import { isAppV2 } from '../../../services/versionManager';
import gaEvents from '../../../services/analytics_events';

interface DefaultHeaderProps {
  name: string;
  color?: string;
  discreteBorder?: boolean;
}

const HeaderMobile = () => {
  const { t } = useTranslation();

  const [openModalInvite, setOpenModalInvite] = useState<boolean>(false);

  const [loadingSearch, setLoadingSearch] = useState(false);
  // const [loadingSearchContainer, setLoadingSearchContainer] = useState(true);
  const [userBalance, setUserBalance] = useState<UserBalance>();
  const [isLoadingUserBalance, setIsLoadingUserBalance] = useState<boolean>(
    true
  );
  // const [width, setWidth] = useState(0);

  const { getIsAuthenticated, user } = useAuth();
  const { getUserBalance } = useUser();
  const { getAdvertisersByString } = useHome();

  const router = useRouter();
  // const [showElement, setShowElement] = useState(true);
  const refreshBalance = useCallback(() => {
    try {
      setIsLoadingUserBalance(true);
      getUserBalance().then(userBalanceData => {
        if (userBalanceData) {
          setUserBalance(userBalanceData);
        }
        setIsLoadingUserBalance(false);
      });
    } catch (error) {
      setIsLoadingUserBalance(false);
      console.log(error);
      Sentry.captureException(error);
    }
  }, [getUserBalance]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOnPopInAppPage = useCallback((event: any) => {
    if (isOnPopQuest(event) && event.detail.dataFromPage.quest.is_rewarded === true) {
      refreshBalance();
    }
  }, [refreshBalance]);

  useEffect(() => {
    if(!isAppV2()) {
      return;
    }

    window.addEventListener('onPopInAppPage', handleOnPopInAppPage);

    return () => {
      window.removeEventListener('onPopInAppPage', handleOnPopInAppPage);
    };
  }, [handleOnPopInAppPage]);

  useEffect(() => {
    const localToken = localStorage.getItem('@coingoback:token');

    async function getUserBalanceData() {
      try {
        const userBalanceData = await getUserBalance();
        if (userBalanceData) {
          setUserBalance(userBalanceData);
        }
        setIsLoadingUserBalance(false);
        // setLoadingSearchContainer(false);
      } catch (error) {
        setIsLoadingUserBalance(false);
        // setLoadingSearchContainer(false);

        console.log(error);
        Sentry.captureException(error);
      }
    }

    if (localToken) {
      getUserBalanceData();
    }
  }, [getUserBalance]);

  useEffect(() => {
    // setWidth(window.innerWidth);

    function handleResize() {
      // setWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleSearch = async (inputValue: string) => {
    try {
      const response = await getAdvertisersByString(inputValue);

      return response.map(advertiser => {
        return { value: advertiser.slug, label: advertiser.name };
      });
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
    }
  };

  const searchDebounced = AwesomeDebouncePromise(handleSearch, 500);

  const handleDebounceSearch = async (inputValue: string) => {
    return await searchDebounced(inputValue);
  };

  const handleSelect = (option: Option) => {
    if (option) {
      setLoadingSearch(true);
      localStorage.setItem('@coingoback:searchLoading', 'true');

      const loadingInterval = setInterval(() => {
        const searchLoading = localStorage.getItem('@coingoback:searchLoading');
        if (!searchLoading) {
          clearInterval(loadingInterval);
          setLoadingSearch(false);
        }
      }, 200);

      router.push(`/store/${option.value}`);
    }
  };

  const HomeHeader: React.FC<React.PropsWithChildren<unknown>> = () => {
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

    const open = Boolean(anchorEl);
    const router = useRouter();

    // const showOrHide = () => setShowElement(!showElement);

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleOnIndicationClick = () => {
      setOpenModalInvite(true);
      gaEvents.userGetReferralLink.emit({ page: '/home' });
    }

    return (
      <>
        {openModalInvite && (
          <IndicationExt open={openModalInvite} onClose={() => {setOpenModalInvite(false)}} />
        )}
        <Header>
          <Container>
            {getIsAuthenticated() ? (
              <>
                <ProfileContainer onClick={handleClick}>
                  {user !== undefined ? (
                    <>
                      <ProfileImage />
                      <ProfileIcon />
                      <UserName>{t('hello')}, <b>{user?.name.split(' ')[0] ?? ''}</b></UserName>
                    </>
                  ) : (
                    <LoadingUser />
                  )}
                </ProfileContainer>
                <div className="balance" onClick={() => router.push('/cashback') }>
                  <div className="balance-icon">
                    <CoinIcon />
                  </div>
                  <span style={{marginRight: 7, fontWeight: 500}}>{isLoadingUserBalance ? '.......' : userBalance?.total_balance_sat_formatted.split(' ')[0]}</span>
                </div>
              </>
            ) : (
              <div className="entrarapp">
                <button
                  onClick={() => {
                    router.push('/login');
                  }}
                >
                  {t('components.header.sign-in-button')}
                </button>
                <div className="divider"></div>
                <button
                  onClick={() => {
                    router.push('/signup');
                  }}
                >
                  {t('components.header.sign-up-button')}
                </button>
              </div>
            )}
            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <ProfileModal onClose={handleClose} />
            </Popover>
          </Container>
          <BoxSaldo>
            {user !== undefined && (
              <div className="today-balance" style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <span>{t('components.header.today-you-earned')}</span>
                <p><CoinOrangeIcon /> {isLoadingUserBalance ? '...' : userBalance?.total_balance_earned_today_sat_formatted.split(' ')[0]}</p>
              </div>
            )}
          </BoxSaldo>
          <div className="indication" onClick={handleOnIndicationClick}>
            <AwardIcon />
            <div className='indication-content'>
              <p>{t('components.indication-bar.refer-cointimes-and-earn')}</p>
              <span>{t('components.indication-bar.earn-points-daily-for-the-xtimes-airdrop')}</span>
            </div>
          </div>
          {/* <div className="mobileSearch">
            {getIsAuthenticated() ? (
              loadingSearchContainer ? (
                <LoadingSearchMobile />
              ) : (
                <SearchContainer>
                  <SearchButton style={{ border: 'none', boxShadow: 'none', backgroundColor: 'transparent' }}>
                    {loadingSearch ? (
                      <CircularProgress size={14} className="loading" />
                    ) : (
                      <Search height={14} />
                    )}
                  </SearchButton>
                  <SearchAutocomplete
                    placeholder="Buscar lojas com cashback"
                    loadOptions={handleDebounceSearch}
                    onChange={handleSelect}
                  />
                </SearchContainer>
              )
            ) : (
              <SearchContainer>
                <SearchButton style={{ border: 'none', boxShadow: 'none', backgroundColor: 'transparent' }}>
                  {loadingSearch ? (
                    <CircularProgress size={14} className="loading" />
                  ) : (
                    <Search height={14} />
                  )}
                </SearchButton>
                <SearchAutocomplete
                  placeholder="Buscar lojas com cashback"
                  loadOptions={handleDebounceSearch}
                  onChange={handleSelect}
                />
              </SearchContainer>
            )}
          </div> */}
        </Header>
      </>
    );
  };

  const HeaderMobileWeb: React.FC<React.PropsWithChildren<unknown>> = () => {
    const router = useRouter();

    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    return (
      <ContainerApp>
        <div className="header">
          <Link href="/">
            <Icon className="icon" />
          </Link>
          <div className="mobileSearch">
            <SearchContainer $variant="filled">
              <SearchButton $transparent>
                {loadingSearch ? (
                  <CircularProgress size={14} className="loading" />
                ) : (
                  <Search height={14} />
                )}
              </SearchButton>
              <SearchAutocomplete
                placeholder="Buscar lojas com cashback"
                loadOptions={handleDebounceSearch}
                onChange={handleSelect}
              />
            </SearchContainer>
          </div>
          {getIsAuthenticated() ? (
            <>
              <ProfileContainerApp onClick={handleClick}>
                <DefaultProfileImageApp>
                  <User />
                </DefaultProfileImageApp>
              </ProfileContainerApp>
            </>
          ) : (
            <div className="entrarapp">
              <button
                onClick={() => {
                  router.push('/login');
                }}
              >
                Login
              </button>
              <div className="divider"></div>
              <button
                onClick={() => {
                  router.push('/signup');
                }}
              >
                Cadastro
              </button>
            </div>
          )}
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <ProfileModal onClose={handleClose} />
          </Popover>
        </div>
      </ContainerApp>
    );
  };

  const DefaultHeader: React.FC<React.PropsWithChildren<DefaultHeaderProps>> = ({ name, color, discreteBorder = false }) => {
    const { back } = useRouter();

    return (
      <DefaultHeaderContainer $color={color} $border={discreteBorder}>
        <button onClick={back} style={{padding: 0}}>
          <img src={Varrow.src} alt="voltar" />
        </button>
        <h1 style={{fontSize: 'calc(18px + 0.390625vw)'}}>{name}</h1>
        <div />
      </DefaultHeaderContainer>
    );
  };

  const DynamicallyHeader = () => {
    const { pathname } = useRouter();

    if (pathname === '/home') {
      return <HomeHeader />;
    } else if (pathname.includes('/news')) {
      return <DefaultHeader name="Notícias" />;
    } else if (pathname.includes('/store')) {
      return <DefaultHeader name="Ganhe bitcoin" />;
    } else if (pathname === '/cashback') {
      return <DefaultHeader name={t('pages.cashback.title')} />;
    } else if (pathname === '/cashout') {
      return <DefaultHeader name={t('pages.cashout.title')} />;
    } else if (pathname.includes('/recommendation')) {
      return <DefaultHeader name={t('pages.recommendation.title')} />;
    } else if (pathname.includes('/quests')) {
      return <DefaultHeader name={t('pages.quests.title')} />;
    } else if (pathname.includes('/read-to-earn')) {
      return <DefaultHeader name="Read to Earn" />;
    } else if (pathname.includes('/external-wallets')) {
      return <DefaultHeader name={t('pages.external-wallets.title')} />;
    } else if (pathname.startsWith('/airdrop/[slug]/season')) {
      return <></>
    } else if (pathname.startsWith('/airdrop')) {
      return <DefaultHeader name="Airdrop" discreteBorder />;
    } else if (pathname === '/help') {
      return <DefaultHeader name="Ajuda" />;
    }
    else if (pathname === '/market') {
      return <DefaultHeader name="Mercado" />;
    } else if (pathname === '/discover') {
      return <DefaultHeader name="Descobrir" />;
    } else if (pathname === '/weare') {
      return <HeaderMobileWeb />;
    } else if (pathname === '/') {
      return <HeaderMobileWeb />;
    } else {
      return <></>;
    }
  };

  return <DynamicallyHeader />;
};

export default HeaderMobile;
