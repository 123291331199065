import { useEffect, useRef, useState } from 'react';
import * as Sentry from "@sentry/react";

import { Search, User } from 'react-feather';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import Popover from '@mui/material/Popover';
import Link from 'next/link';

import SearchAutocomplete from '../SearchAutocomplete';
import ProfileModal from '../../components/ProfileModal';
import { UserBalance } from '../../models/user';

import { useHome } from '../../hooks/home';
import { useAuth } from '../../hooks/auth';
import { useUser } from '../../hooks/user';

import Logo from '../../assets/logo-novo-cointimes-white.svg';
import Coin from '../../assets/coin.svg';
import chromeIcon from '../../assets/chrome-icon.png';

import LoadingUserBalance from '../Shimmer/LoadingUserBalance';

import {
  Container,
  SearchContainer,
  SearchButton,
  BTCInfo,
  Title,
  Subtitle,
  ProfileContainer,
  ProfileImage,
  DefaultProfileImage,
  UserName,
  LogoContainer,
  Content,
  Menu,
  // MenuDivider,
  Invite,
} from './styles';
import LoadingUser from '../Shimmer/LoadingUser';
import { Option } from '../../models/searchAutocomplete';
import router from 'next/router';
// import { Button } from '../UI';
import { CircularProgress } from '@mui/material';
import HeaderMobile from './Mobile';
import { FlatButton } from '../UI/Button/styles';
import { t } from 'i18next';

const Header: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { getAdvertisersByString } = useHome();
  const { getIsAuthenticated, user, localCountry } = useAuth();
  const { getUserBalance } = useUser();

  const [show, setShow] = useState<boolean>(true);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [userBalance, setUserBalance] = useState<UserBalance>();
  const [isLoadingUserBalance, setIsLoadingUserBalance] = useState(true);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [width, setWidth] = useState(0);

  const prevScrollY = useRef(0);
  const open = Boolean(anchorEl);

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerWidth < 770) return;

      const currentScrollY = window.scrollY;
      if (prevScrollY.current < currentScrollY && show) {
        setShow(false);
      }
      if (prevScrollY.current > currentScrollY && !show) {
        setShow(true);
      }
      prevScrollY.current = currentScrollY;
    };
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, [show]);

  useEffect(() => {
    setWidth(window.innerWidth);

    const localToken = localStorage.getItem('@coingoback:token');

    async function getUserBalanceData() {
      try {
        setIsLoadingUserBalance(true);
        const userBalanceData = await getUserBalance();
        if (userBalanceData) {
          setUserBalance(userBalanceData);
        }
        setIsLoadingUserBalance(false);
      } catch {
        setIsLoadingUserBalance(false);
      }
    }

    if (localToken) {
      getUserBalanceData();
    }
  }, [getUserBalance]);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [width]);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSearch = async (inputValue: string) => {
    try {
      const response = await getAdvertisersByString(inputValue);

      return response.map(advertiser => {
        return { value: advertiser.slug, label: advertiser.name };
      });
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
    }
  };

  const searchDebounced = AwesomeDebouncePromise(handleSearch, 500);

  const handleDebounceSearch = async (inputValue: string) => {
    return await searchDebounced(inputValue);
  };

  const handleSelect = (option: Option) => {
    if (option) {
      setLoadingSearch(true);
      localStorage.setItem('@coingoback:searchLoading', 'true');

      const loadingInterval = setInterval(() => {
        const searchLoading = localStorage.getItem('@coingoback:searchLoading');
        if (!searchLoading) {
          clearInterval(loadingInterval);
          setLoadingSearch(false);
        }
      }, 200);

      router.push(`/store/${option.value}`);
    }
  };

  return (
    <>
      {width < 770 ? (
        <HeaderMobile />
      ) : (
        <Container $show={show}>
          <Content>
            <Link href="/home" passHref>
              <LogoContainer>
                <Logo height={41} width={194} />
              </LogoContainer>
            </Link>
            <SearchContainer>
              {localCountry === 'BR' ?
              <>
                <SearchButton $transparent>
                  {loadingSearch ? (
                    <CircularProgress size={14} className="loading" />
                  ) : (
                    <Search height={14} />
                  )}
                </SearchButton>
                <SearchAutocomplete
                  dark
                  placeholder="Buscar lojas com cashback"
                  loadOptions={handleDebounceSearch}
                  onChange={handleSelect}
                />
              </> : <></> }
            </SearchContainer>
            {getIsAuthenticated() ? (
              <>
                {!isLoadingUserBalance ? (
                  <BTCInfo>
                    <Title>
                      <Link href={'/cashback'}>
                        <a style={{color: 'inherit'}}>
                        {userBalance?.total_balance_btc_formatted ?? ''}
                        </a>
                      </Link>
                    </Title>
                    <Subtitle>
                      <Link href={'/cashback'}>
                        <a style={{color: 'inherit'}}>
                        ≅{' '}
                        {userBalance?.total_balance_base_currency_formatted ?? ''}
                        </a>
                      </Link>
                    </Subtitle>
                  </BTCInfo>
                ) : (
                  <LoadingUserBalance />
                )}
                <ProfileContainer onClick={handleClick}>
                  <ProfileImage />
                  <DefaultProfileImage>
                    <User height={24} />
                  </DefaultProfileImage>
                  {user !== undefined ? (
                    <UserName>{user?.name ?? ''}</UserName>
                  ) : (
                    <LoadingUser />
                  )}
                </ProfileContainer>
              </>
            ) : (
              <div className="authContent">
                <a
                  target="_blank"
                  href="https://chrome.google.com/webstore/detail/coingoback/cbohbbfalnakfabmclapcglchjnpljdm?hl=pt-BR"
                  style={{paddingRight: 15}} rel="noreferrer"
                >
                  <FlatButton $dark>
                    <img src={chromeIcon.src} alt="Chrome Icon" height="18" />
                    <span>{t('components.header.install-extension')}</span>
                  </FlatButton>
                </a>
                <div className="divider"></div>
                <Link href="/login" passHref>
                  <FlatButton $dark>
                    <span>{t('components.header.sign-in-button')}</span>
                  </FlatButton>
                </Link>
                <Link href="/signup" passHref>
                  <FlatButton>
                    <span>{t('components.header.sign-up-button')}</span>
                  </FlatButton>
                </Link>
              </div>
            )}
            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <ProfileModal onClose={handleClose} />
            </Popover>
          </Content>
          <Menu>
            <div className="menuItems">
              <Link href="/home" passHref>
                <button className="menuItem">
                  <span>{t('components.header.home')}</span>
                </button>
              </Link>

              <a href={localCountry == 'BR' ? 'https://cointimes.com.br/noticias/' : 'https://cointimes.app/news/'} target='_blank' rel="noreferrer">
                <button className="menuItem">
                  <span>{t('components.header.news')}</span>
                </button>
              </a>
              <Link href="/cashback" passHref>
                <button className="menuItem">
                  <span>{t('components.header.wallet')}</span>
                </button>
              </Link>
              <Link
                href="https://chrome.google.com/webstore/detail/cointimes/cbohbbfalnakfabmclapcglchjnpljdm?hl=pt-BR"
                passHref
              >
                <button className="menuItem">
                  <span>{t('components.header.install-extension')}</span>
                </button>
              </Link>
              {/* <Link href="/quests" passHref>
                <button className="menuItem">
                  <div className="featured">
                      <span>Quests</span>
                  </div>
                </button>
              </Link> */}
              {localCountry == 'BR' ?
              <Link href="/help" passHref>
                <button className="menuItem">
                  <span>{t('components.header.help')}</span>
                </button>
              </Link> : <></>}
            </div>
            <Link href="/recommendation" passHref>
              <Invite>
                <span>{t('components.header.refer-and-earn')}</span>
                <div className="alignIcon">
                  <Coin />
                </div>
              </Invite>
            </Link>
          </Menu>
        </Container>
      )}
    </>
  );
};

export default Header;
