import { useState } from 'react';

import { components } from 'react-select';

import { Container, CustomSelect } from './styles';

interface Option {
  value: string;
  shortLabel?: string;
  customElemBegin?: JSX.Element;
  label: string;
}

interface Props {
  options: Option[];
  defaultOption?: Option;
  onSelect?: (option: Option) => void;
  enableShortLabel?: boolean;
  placeholder?: string;
  value?: Option;
  enableValue?: boolean;
}

const Select: React.FC<React.PropsWithChildren<Props>> = ({
  options,
  defaultOption,
  onSelect,
  enableShortLabel,
  placeholder,
  value,
  enableValue,
}) => {
  const [selectedOption, setSelectedOption] = useState<Option>(defaultOption);

  const selectStyles = {
    container: provided => ({
      ...provided,
      width: '100%',
    }),
    control: provided => ({
      ...provided,
      border: 'none',
      boxShadow: 'none',
      minHeight: 34,
      height: 34,
      borderRadius: '8px',
    }),
    option: provided => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
    }),
    menu: provided => ({
      ...provided,
      width: enableShortLabel ? 'calc(100% + 46px)' : 'calc(100% + 8px)',
      marginLeft: enableShortLabel ? -46 : -8,
      marginTop: 4,
    }),
    menuList: provided => ({
      ...provided,
      maxHeight: 180,
      overflow: 'auto',
    }),
    placeholder: provided => ({
      ...provided,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '96%'
    }),
  };

  const { Option } = components;

  const StyledOption = props => (
    <Option {...props}>
      {props.data.customElemBegin}
      {enableShortLabel && (
        <strong style={{ marginRight: 12 }}>{props.data.shortLabel}</strong>
      )}
      {props.data.label}
    </Option>
  );

  const handleSelect = (value: Option) => {
    setSelectedOption(value);
    onSelect?.(value);
  };

  return (
    <Container $enableShortLabel={enableShortLabel}>
      {selectedOption?.customElemBegin}
      {enableShortLabel && <strong>{selectedOption?.shortLabel}</strong>}
      <CustomSelect
        value={enableValue ? value : selectedOption}
        defaultValue={selectedOption}
        options={options}
        components={{
          IndicatorSeparator: () => null,
          Option: StyledOption,
        }}
        onChange={handleSelect}
        placeholder={placeholder}
        styles={selectStyles}
      />
    </Container>
  );
};

export default Select;
