import Head from 'next/head';
import { Container, PageContent, ScrollContent } from './styles';

const EmptyLayout: React.FC<React.PropsWithChildren<unknown>> = props => {
  return (
    <>
      <Head>
        <title>Cointimes</title>
      </Head>
      <Container>
        <PageContent>
          <ScrollContent>
            {props.children}
          </ScrollContent>
        </PageContent>
      </Container>
    </>
  );
};

export default EmptyLayout;
