import { AxiosResponse } from 'axios';
import { useState, createContext, useContext } from 'react';
import axiosClient from '../services/axiosClient';

interface AllData {
  user_email: string;
  recover_code: string;
  new_password: string;
}

interface ForgotPasswordContextData {
  data: {
    user_email: string;
  };
  setFormValues: (values: AllData) => void;
  putEmail: (email: string) => Promise<void>;
  putPassword: (input: AllData) => Promise<void>;
}

const ForgotPasswordContext = createContext<ForgotPasswordContextData>(
  {} as ForgotPasswordContextData
);

export const ForgotPasswordProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [data, setData] = useState<AllData>({
    user_email: '',
    recover_code: '',
    new_password: '',
  });

  const setFormValues = async (values: AllData) => {
    setData(prevValues => ({
      ...prevValues,
      ...values,
    }));
  };

  const putEmail = async (email: string) => {
    return new Promise<void>((resolve, reject) => {
      axiosClient
        .put<{ email: string }, AxiosResponse<void>>(
          `/api/password/forgot/${email}`
        )
        .then(async response => {
          resolve(response?.data);
        })
        .catch(error => {
          reject(error.response?.data ? error.response.data : error.message);
        });
    });
  };

  const putPassword = async (input: AllData) => {
    return new Promise<void>((resolve, reject) => {
      axiosClient
        .post<{ input: AllData }, AxiosResponse<void>>(
          `/api/password/recover`,
          {
            user_email: input.user_email,
            recover_code: input.recover_code,
            new_password: input.new_password,
          }
        )
        .then(async response => {
          resolve(response?.data);
        })
        .catch(error => {
          reject(error.response?.data ? error.response.data : error.message);
        });
    });
  };

  return (
    <ForgotPasswordContext.Provider
      value={{ data, setFormValues, putEmail, putPassword }}
    >
      {children}
    </ForgotPasswordContext.Provider>
  );
};

function useForgotPassword(): ForgotPasswordContextData {
  const context = useContext(ForgotPasswordContext);

  if (!context) {
    throw new Error(
      ' useForgotPassword must be used within an ForgotPasswordProvider '
    );
  }
  return context;
}

export { ForgotPasswordContext, useForgotPassword };
