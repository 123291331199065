import { Option } from '../../models/searchAutocomplete';

import { Container, CustomSelect } from './styles';

interface Props {
  dark?: boolean;
  placeholder: string;
  loadOptions: (inputValue: string) => Promise<Option[]>;
  onChange:(option: Option) => void;
}

const SearchAutocomplete: React.FC<React.PropsWithChildren<Props>> = ({ dark, placeholder, loadOptions, onChange }) => {
  return (
    <Container>
      <CustomSelect
        instanceId="react-select"
        classNamePrefix="react-select"
        placeholder={placeholder}
        maxMenuHeight={200}
        noOptionsMessage={() => 'Não encontrado'}
        isClearable
        onChange={(option: Option) => option && onChange(option)}
        loadOptions={loadOptions}
        loadingMessage={() => 'Carregando...'}
        components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
        $dark={dark}
        theme={(theme) => ({
          ...theme, colors: { ...theme.colors, primary: '#FE501C', primary25: '#fe501c1f', primary50: '#fe501c1f', primary75: '#fe501c1f', }})}
      />
    </Container>
  );
};

export default SearchAutocomplete;
