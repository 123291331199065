import Head from 'next/head';
import { Container, PageContent, Content, ScrollContent } from './styles';

import Header from '../../components/Header';

const MainLayout: React.FC<React.PropsWithChildren<unknown>> = props => {
  return (
    <>
      <Head>
        <title>Cointimes</title>
      </Head>
      <Container>
        <PageContent>
          <Header />
          <ScrollContent>
            <Content>{props.children}</Content>
          </ScrollContent>
        </PageContent>
      </Container>
    </>
  );
};

export default MainLayout;
