import * as List from './styles';
import Link from 'next/link';
import { ArrowRight } from '@mui/icons-material';
import ProgressBar from '../ProgressBar';

type ItemActionType = {
  href: string;
};

export const ItemAction: React.FC<React.PropsWithChildren<ItemActionType>> = props => {
  return (
    <List.ItemText>
      <Link href={props.href} passHref>
        <a>
          <List.Row>
            {props.children}
            <ArrowRight />
          </List.Row>
        </a>
      </Link>
    </List.ItemText>
  );
};

type ItemProgressBarType = {
  value: number;
  status?: string;
};
export const ItemProgressBar: React.FC<React.PropsWithChildren<ItemProgressBarType>> = ({
  status,
  value,
}) => {
  if (status) {
    switch (status) {
      case 'PENDING':
      case 'CONFIRMED':
        return <ProgressBar value={value} bgColor="warning" />;

      case 'AVAILABLE':
        return <ProgressBar value={100} bgColor="success" />;

      case 'CANCELED':
        return <ProgressBar value={100} bgColor="danger" />;

      default:
        return <ProgressBar value={100} />;
    }
  } else {
    return <ProgressBar value={value} />;
  }
};

const components = { ItemAction, ItemProgressBar, ...List };

export default components;
