import styled from 'styled-components';

type FillerType = {
  $value?: number;
  $bgColor?: string;
};

export const Container = styled.div`
  height: 12px;
  width: 100%;
  background: #ebebeb;
  border-radius: 50px;
  margin: 0 20px;
`;

export const Filler = styled.div<FillerType>`
  height: 100%;
  width: ${props => props.$value || 100}%;
  background: ${props => props.theme.colors[props.$bgColor || 'primary'].main};
  border-radius: 'inherit';
  border-radius: 50px;
  text-align: 'right';
`;
