import { gql } from '@apollo/client';
import { createContext, useContext } from 'react';

import {
  IgetAdsAccount,
  ReferralInfo,
  UserBalance,
  UserCode,
  UserXPZ,
} from '../models/user';

import { initializeApollo } from '../services/apolloClient';

const apolloClient = initializeApollo();

interface UserContextData {
  getUserBalance(): Promise<UserBalance>;
  getOrCreateReferralCode(): Promise<UserCode>;
  setReferrer(referral_code: string): Promise<string>;
  getUserxpz(name_file: string): Promise<UserXPZ>;
  getAdsAccount(): Promise<IgetAdsAccount>;
  getReferralInfo(): Promise<ReferralInfo>;
}

const UserContext = createContext<UserContextData>({} as UserContextData);

export const UserProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const getUserBalance = async (): Promise<UserBalance> => {
    return new Promise<UserBalance>((resolve, reject) => {
      apolloClient
        .query({
          query: gql`
            query {
              totalUserBalance {
                total_balance_btc_formatted
                total_balance_base_currency_formatted
                total_balance_sat_formatted
                total_balance_earned_today_sat_formatted
              }
            }
          `,
        })
        .then(async response => {
          resolve(response?.data?.totalUserBalance);
        })
        .catch(error => {
          reject(error.response?.data ? error.response.data : error.message);
        });
    });
  };

  return (
    <UserContext.Provider
      value={{
        getUserBalance,
        getOrCreateReferralCode,
        setReferrer,
        getUserxpz,
        getAdsAccount,
        getReferralInfo,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

const getOrCreateReferralCode = async (): Promise<UserCode> => {
  return new Promise<UserCode>((resolve, reject) => {
    apolloClient
      .mutate({
        mutation: gql`
          mutation {
            getOrCreateReferralCode {
              referral_code
            }
          }
        `,
      })
      .then(async response => {
        resolve(response?.data?.getOrCreateReferralCode);
      })
      .catch(error => {
        reject(error.response?.data ? error.response.data : error.message);
      });
  });
};

const setReferrer = async (referral_code: string): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    apolloClient
      .mutate({
        mutation: gql`
          mutation($input: SetReferrerInput!) {
            setReferrer(input: $input) {
              message
            }
          }
        `,
        variables: {
          input: {
            referral_code,
          },
        },
      })
      .then(async response => {
        resolve(response?.data?.setReferrer?.message);
      })
      .catch(error => {
        reject(error.response?.data ? error.response.data : error.message);
      });
  });
};

const getReferralInfo = async (): Promise<ReferralInfo> => {
  return new Promise<ReferralInfo>((resolve, reject) => {
    apolloClient
      .query({
        query: gql`
          query {
            referralInfo {
              balance_available_earned
              balance_available_earned_formatted
              balance_pending_earned
              balance_pending_earned_formatted
              referred_users_earning_count
              referred_users_count
              airdrop_point_earned
            }
          }
        `,
      })
      .then(async response => {
        resolve(response?.data?.referralInfo);
      })
      .catch(error => {
        reject(error.response?.data ? error.response.data : error.message);
      });
  });
};

const getUserxpz = async (name_file: string): Promise<UserXPZ> => {
  return new Promise<UserXPZ>((resolve, reject) => {
    apolloClient
      .query({
        query: gql`
          query Achievements($name_file: String!) {
            achievements(name_file: $name_file) {
              page {
                title
                description
                logo_url
                primary_color
              }
              progress {
                total
                current
              }
              section_achievements {
                section_key
                section_title
                achievements {
                  title
                  description
                  level_title
                  level_color
                  user_action_title
                  completed
                }
              }
            }
          }
        `,
        variables: {
          name_file,
        },
      })
      .then(async response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error.response?.data ? error.response.data : error.message);
      });
  });
};

const getAdsAccount = async (): Promise<IgetAdsAccount> => {
  return new Promise<IgetAdsAccount>((resolve, reject) => {
    apolloClient
      .query({
        query: gql`
          query {
            adsAccountBalance {
              currency
              balance
              balance_formatted
              balance_available
              balance_available_formatted
              balance_pending
              balance_pending_formatted
              balance_earned_today
              balance_earned_today_formatted
            }
          }
        `,
      })
      .then(async response => {
        resolve(response?.data?.adsAccountBalance);
      })
      .catch(error => {
        reject(error.response?.data ? error.response.data : error.message);
      });
  });
};

function useUser(): UserContextData {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error(' useUser must be used within an UserProvider ');
  }
  return context;
}

export { UserContext, useUser };
