import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import Button from './Button';
import { NewContainer } from './styles';

import { faBookOpenReader, faCartShopping, faChartLine, faCompass, faHome, faWallet } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isAppV2 } from '../../services/versionManager';

import XTimesLogoWhite from '../../assets/Airdrop/xtimes_logo_white.svg';

const NewMobileMenu: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { pathname: activePage, push } = useRouter();
  const [showMobileMenu, setShowMobileMenu] = useState(true);

  const [lastScrollPos, setLastScrollPos] = useState(0);

  const handleChangePage = (page: string) => {
    if (page.startsWith('/open-in-app')) {
      window.location.href = page;
    } else {
      push(page);
    }

    setShowMobileMenu(!page.startsWith('/discover'));
  };

  const menuItems = [
    {
      'page': '/home',
      'icon': faHome
    },
    {
      'page': '/read-to-earn',
      'icon': faBookOpenReader
    },
    {
      'page': '/discover',
      'icon': faCompass
    },
    {
      'page': '/airdrop/xtimes/season',
      'custom-icon': <XTimesLogoWhite height={44} width={44} />
    },
    {
      'page': '/market',
      'icon': faChartLine
    },
    {
      'page': '/stores',
      'icon': faCartShopping
    },
    {
      'page': '/cashback',
      'icon': faWallet
    },
  ]

  useEffect(() => {
    setShowMobileMenu(
      !activePage.startsWith('/discover')
      &&
      !activePage.startsWith('/airdrop/[slug]/season')
    );
  }, [activePage]);

  const onScroll = useCallback(() => {
    if (window.location.pathname.startsWith('/discover')
      || window.location.pathname.startsWith('/airdrop/xtimes/season')) {
      return;
    }

    const scrollY = Math.max(0, window.scrollY);
    setShowMobileMenu(lastScrollPos > scrollY);

    setLastScrollPos(scrollY);
  }, [lastScrollPos]);

  useEffect(() => {
    const path = window.location.pathname;
    if (path.startsWith('/discover') || path.startsWith('/airdrop/xtimes/season')) {
      setShowMobileMenu(false);
      return;
    }

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    }
  }, [onScroll]);

  return (
    <NewContainer className={showMobileMenu === true ? 'show' : 'hide'} style={{ padding: '0px 10px', paddingBottom: '14px' }}>
      <div id='hotjar_element_holder' style={{ padding: '0px 20px'}} />
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 60,
        border: activePage.startsWith('/airdrop') ? '1px solid #878787' : 'none',
        backgroundColor: 'black',
        borderRadius: 30,
        padding: '0px 10px'
      }}>
        {menuItems.filter(
          (value) => (isAppV2() || !value.page.startsWith('/open-in-app'))
        ).map((value, idx) => {
          return <Button
            key={idx}
            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingBottom: '0px' }}
            onClick={() => handleChangePage(value['page'])}
            $active={activePage === value['page'] || window.location.pathname === value['page']}
          >{value["custom-icon"]
              ? <div style={{ flex: '1', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>{value["custom-icon"]}</div>
            : <FontAwesomeIcon fontSize='calc(15px + 0.390625vw)' icon={value['icon']} />}
          </Button>
        })}
      </div>
    </NewContainer>
  );
};

export default NewMobileMenu;
