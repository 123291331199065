import styled, { css } from 'styled-components';

interface ContainerProps {
  $show: boolean;
}

interface SearchContainerProps {
  variant?: string;
}

interface SearchButtonProps {
  transparent?: boolean;
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  position: fixed;
  z-index: 5;

  display: flex;
  flex-direction: column;

  background: black;
  border-bottom: 1px solid #000;

  ${props =>
    props.$show &&
    css`
      visibility: visible;
      transition: all 500ms ease-in;
    `}

  ${props =>
    !props.$show &&
    css`
      visibility: hidden;
      transition: all 500ms ease-out;
      transform: translate(0, -100%);
    `}
`;

export const Content = styled.div`
  width: 100%;
  height: 82px;
  padding: 16px 0;

  display: flex;
  align-items: center;

  border-bottom: 1px solid #000;

  div.authContent {
    display: flex;
    align-items: center;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      padding: 10px 20px;
      margin: 0 6px;

      span {
        font-family: ${props => props.theme.font.default};
        white-space: nowrap;
      }
    }

    button.signIn {
      background: #000;
      color: #fff;
    }

    button.signUp {
      background: ${props => props.theme.colors.primary.main};
    }

    div.divider {
      height: 40px;
      width: 1px;
      background-color: #a2a2a2;
      margin: 0 6px;
    }
  }
`;

export const SearchContainer = styled.div<SearchContainerProps>`
  width: 100%;

  display: flex;
  align-items: center;

  border-radius: 22px;
  background: #fe501c1f;

  ${props =>
    props.variant === 'filled' &&
    css`
      background: ${props.theme.colors.primary.hover};
      border-radius: 20px;
      padding: 0;
    `}
  @media (max-width: 770px) {
    width: 100%;

    background: unset;
    border: 1px solid #000;
    box-shadow: 4px 4px #000;
  }
`;

export const SearchButton = styled.div<SearchButtonProps>`
  min-width: 35px;
  height: 35px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 10px;
  background-color: ${props =>
    props.transparent ? 'white' : props.theme.colors.primary.hover};
  color: ${props => props.theme.colors.primary.main};

  .loading {
    color: ${props => props.theme.colors.primary.main};
    @media (max-width: 770px) {
      color: #000;
    }
  }
  @media (max-width: 770px) {
    color: #000;
  }
`;

export const BTCInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;

  min-width: 180px;
  height: 100%;
  padding: 0 20px;

  margin-right: 20px;
  border-right: 1px white solid;
`;

export const Title = styled.span`
  font-size: 14px;
  font-family: ${props => props.theme.font.default};
  font-weight: bold;
  color: #fff;
`;

export const Subtitle = styled.span`
  font-size: 14px;
  font-family: ${props => props.theme.font.default};
  color: #fff;
`;

export const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 280px;
  margin: 0 20px;

  color: white;
  cursor: pointer;
  @media (max-width: 769px) {
    width: 60px;
    margin: 0;
  }
`;

export const ProfileImage = styled.img``;

export const DefaultProfileImage = styled.div`
  padding: 4px 6px;
  margin: 0 10px;

  border: 2px solid;
  border-radius: 8px;
`;

export const UserName = styled.span`
  padding: 2px;

  font-size: 14px;
  font-family: ${props => props.theme.font.default};
  font-weight: bold;
`;

export const LogoContainer = styled.div`
  height: 82px;
  padding: 0 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
`;

export const Menu = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 46px;
  transition: all 150ms linear;

  .menuItems {
    display: flex;
    align-items: center;
  }

  .menuIcon {
    padding: 0 15px;
  }

  .menuItem {
    padding: 0 15px;
    color: white;

    &:hover span {
      color: ${props => props.theme.colors.primary.main};
      transition: all 150ms linear;
    }

    &:active {
      transform: scale(1.02);
    }

    span {
      font-size: 14px;
      font-family: ${props => props.theme.font.default};
      text-align: left;
    }
  }

  @keyframes animateBg {
    0% {
      background-position: 0% 0;
    }
    100% {
      background-position: 100% 0;
    }
  }

  @keyframes animateBg2 {
    0% {
      background-position: 0% 0;
    }
    100% {
      background-position: 100% 0;
    }
  }

  .featured {
    animation: animateBg 2s 2 forwards ease-in-out;
    background-size: 500% 100%;
    background-image: linear-gradient(80deg, #fe501c, #fe501c, #db7878, #fe501c, #fe501c);
    padding: 5px;
    border-radius: 10px;
    border: none;

    span {
      color: white!important;
    }
  }
`;

export const MenuDivider = styled.div`
  background-color: #000;

  width: 1px;
  height: 60%;
`;

export const Invite = styled.button`
  height: 100%;
  display: flex;
  align-items: center;

  border-radius: 22px;
  padding: 0 20px;

  span {
    font-size: 18px;
    font-family: ${props => props.theme.font.default};
    font-weight: bold;
    color: #fff;
  }

  strong.yellow {
    color: #fcda00;
  }

  .alignIcon {
    padding-left: 20px;
  }
`;
