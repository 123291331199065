import { useEffect, useState } from 'react';

import { Container, Content, ShereSocial, WppButton } from './styles';

import {
  Facebook,
  FilterNone,
  Reddit,
  Telegram,
  Twitter,
  WhatsApp,
} from '@mui/icons-material';
import { t } from 'i18next';
import { useRouter } from 'next/router';
import {
  FacebookShareButton,
  RedditShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import Modal from '..';
import { useAuth } from '../../../hooks/auth';
import { useUser } from '../../../hooks/user';
import withAuth from '../../HigherOrder/withAuth';
import Skeleton from '../../Skeleton';
import { toastError } from '../../Toast';
import { FlatButton } from '../../UI/Button/styles';

interface Props {
  open: boolean;
  onClose: (done?: boolean) => void;
}

const CopyButton = ({ text }) => {
  const [showPopup, setShowPopup] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(text).then(() => {
      setShowPopup(true);
      setTimeout(() => setShowPopup(false), 2000);
    });
  };

  return (
    <Content>
      {showPopup && <div className="popup">Copiado!</div>}
      <button onClick={handleCopy}>
        <FilterNone />
      </button>
    </Content>
  );
};

const getShareSocialMessage = (userName: string) => {
  return t('components.indication-bar.share-social-message').replaceAll(':username', userName);
}

const WhatsappButton = ({ referralCode, userName }) => {
  const shareUrl = `https://app.cointimes.com.br/signup?referral_code=${referralCode}`;
  const title = getShareSocialMessage(userName);

  return (
    <WhatsappShareButton style={{ width: '100%' }} url={shareUrl} title={title}>
      <WppButton style={{ width: '100%' }}>
        <WhatsApp />
        <div style={{ width: '100%' }}>{t('share')}</div>
      </WppButton>
    </WhatsappShareButton>
  );
};

const TelegramButton = ({ referralCode, userName }) => {
  const shareUrl = `https://app.cointimes.com.br/signup?referral_code=${referralCode}`;
  const title = getShareSocialMessage(userName);

  return (
    <TelegramShareButton url={shareUrl} title={title}>
      <Telegram />
    </TelegramShareButton>
  );
};

const TwitterButton = ({ referralCode, userName }) => {
  const shareUrl = `https://app.cointimes.com.br/signup?referral_code=${referralCode}`;
  const title = getShareSocialMessage(userName);

  return (
    <TwitterShareButton url={shareUrl} title={title}>
      <Twitter />
    </TwitterShareButton>
  );
};

const FacebookButton = ({ referralCode }) => {
  const shareUrl = `https://app.cointimes.com.br/signup?referral_code=${referralCode}`;
  return (
    <FacebookShareButton url={shareUrl}>
      <Facebook />
    </FacebookShareButton>
  );
};

const RedditButton = ({ referralCode, userName }) => {
  const shareUrl = `https://app.cointimes.com.br/signup?referral_code=${referralCode}`;
  const title = getShareSocialMessage(userName);

  return (
    <RedditShareButton url={shareUrl} title={title}>
      <Reddit />
    </RedditShareButton>
  );
};

const IndicationExt: React.FC<React.PropsWithChildren<Props>> = ({ open, onClose }) => {
  const [userCode, setUserCode] = useState<string>();
  const { getOrCreateReferralCode } = useUser();
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(true);

  const router = useRouter();

  useEffect(() => {
    const getUserCode = async () => {
      try {
        const { referral_code } = await getOrCreateReferralCode();
        setUserCode(referral_code);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        toastError(err);
      }
    };
    getUserCode();
  }, [getOrCreateReferralCode]);

  return (
    <>
      <Modal open={open} onClose={onClose} >
        <Container>
          <h1>{t('share')}</h1>
          <p>{t('components.indication-modal.share-message')}</p>
          <p>{t('components.indication-modal.your-link-is')}:</p>
          {isLoading ? (
            <Skeleton className="row" />
          ) : (
            <div>
              <span className="share-link">
                https://app.cointimes.com.br/signup?referral_code={userCode}
              </span>
              <CopyButton
                text={`https://app.cointimes.com.br/signup?referral_code=${userCode}`}
              />
            </div>
          )}
          {/* <p>
            Você está dando 1.000 satoshis de bônus para seu amigo. Não perca
            tempo!
          </p> */}
          <div style={{ display: 'flex', flexDirection: 'column', gap: 10, width: 200, marginBottom: 20 }}>
            {isLoading ? (
              <>
                <Skeleton className="rowButton" />
                <Skeleton className="rowButton" />
              </>
            ) : (
              <>
                <div style={{ width: '100%' }}>
                  {userCode && (
                    <WhatsappButton referralCode={userCode} userName={user?.name} />
                  )}
                </div>
                <div style={{ width: '100%' }}>
                  <FlatButton $dark style={{ width: '100%', padding: 20 }} onClick={() => {
                    router.push('/recommendation');
                    onClose();
                  }}>
                    {t('components.indication-modal.see-referrals')}
                  </FlatButton>
                </div>
              </>
            )}
          </div>
        </Container>
        <ShereSocial>
          {isLoading ? (
            <Skeleton className="rowSocial" />
          ) : (
            <div>
              {userCode && (
                <TelegramButton referralCode={userCode} userName={user?.name} />
              )}
              {userCode && (
                <TwitterButton referralCode={userCode} userName={user?.name} />
              )}
              {userCode && <FacebookButton referralCode={userCode} />}
              {userCode && (
                <RedditButton referralCode={userCode} userName={user?.name} />
              )}
            </div>
          )}
        </ShereSocial>
      </Modal>
    </>
  );
};

export default withAuth(IndicationExt);
